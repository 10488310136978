import React from 'react'
import { MDXTag } from '@mdx-js/tag'



export const frontmatter = {
  title: 'The Effect Lifecycle',
  author: 'Chris Neale',
  draft: false,
  date: '2018-10-02T23:10:01.00Z',
  tags: ['neon', 'effects']
}
export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<MDXTag name="h3" components={components}>{`The Effect Lifecycle`}</MDXTag>
<MDXTag name="p" components={components}>{`There are several steps that happen to get an effect up and running. When the effect is initialised its constructor() function is called. When the Neon HoC mounts in the DOM the effect's listeners() method is called. Then the wrapped component is observed by Neon's ResizeObserver which calls Neon's resize() method.`}</MDXTag>
<MDXTag name="p" components={components}>{`This in turn calls the effect's cancel() method to stop the requestAnimationFrame callback, then it works out the size of the component and passes that information to the effect's attach() method. The attach() call in turn fire's the effect's init() method. Finally the effect's draw() method is (re)started with a new requestAnimationFrame call.`}</MDXTag>
<MDXTag name="ol" components={components}>
<MDXTag name="li" components={components} parentName="ol">{`Effect constructor() called.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ol">{`Neon HoC mounts`}</MDXTag>
<MDXTag name="li" components={components} parentName="ol">{`Effect listeners() method called.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ol">{`Effect's cancel() method runs to stop animating.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ol">{`Effect's attach() method runs.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ol">{`Effect's init() method runs.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ol">{`Effect's draw() to start animating.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Each stage of the effect lifecycle can be overridden by adding a method to your effect class, but you don't need to that.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  